
//==========  Extends  ==========//

// use these classes and placeholders with @extends
// classes defined here will be output in the final compiled css
// placeholders will not and are only used within sass

// note - extends will not work within media queries


//==========  Helpers and resets  ==========//

// set on .inner containers
%full-width {
    width: 100%;
    max-width: $max-width;
    margin: 0 auto;
}

// clear floats on elements after container
// prevents elements after from wrapping
%clear-fix {

    &:after {
        content: '';
        clear: both;
        display: block;
        width: 100%;
        overflow: hidden;
    }
}

// set child elements to be an automatic equal height
// must be set on parent container (not an ancestor!)
// %clear-fix should be removed (creates extra columns)
// flexbox progressive enhancement
%elastic {
    display: block;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    overflow: hidden;
}

// svg icon reset - use to hide any icons
%no-icon {
    min-height: 0;

    .icon-link {
        min-height: 0;
        padding-left: 0;
    }

    .icon {
        display: none;
    }
}

// reset for images, prevents whitespace around image
%image {
    display: block;
    width: 100%;
}

// default style for blockquotes
%blockquote {
    margin: 0;

    // left quote marks
    &:before {
        content: '\201C';
    }

    // right quote marks
    &:after {
        content: '\201D';
    }

    // reset for p tags that are put in by the CMS editor
    p {
        display: inline;
        margin: 0;
    }
}

// add as class to element or extend
.hidden {
    display: none;
}

// loading animation styles
// set based styles for dots
// configure dot styles with mixin
%loading {
    @include centre(absolute);
    @include loading();

    p {
        margin: 0;
        text-align: center;
    }

    .dot {
        display: inline-block;
        transform: scale(0);
        border-radius: 50%;
        animation: loading 1s ease infinite;

        &:nth-child(2) {
            animation: loading 1s ease infinite 0.3s;
        }

        &:nth-child(3) {
            animation: loading 1s ease infinite 0.6s;
        }
    }
}

// assign the default to a global class
.loading {
    @extend %loading;
}

//========== Slider styles ==========//

// triangular controls
// apply on bx-controls-direction
%bx-controls {

    .bx-next,
    .bx-prev {
        @include centreY(absolute);
        z-index: z(bxslider);

        &:after {
            content: '';
            @include centreY(absolute);
            border-top: 15px solid transparent;
            border-bottom: 15px solid transparent;
            transition: opacity t(link) ease-out;
        }

        &:hover {

            &:after {
                opacity: 0.75;
            }
        }
    }

    .bx-next {
        right: 0;

        &:after {
            right: -0.5rem;
            border-left: 15px solid $color-secondary;
        }
    }

    .bx-prev {
        left: 0;

        &:after {
            left: -0.5rem;
            border-right: 15px solid $color-secondary;
        }
    }
}

// circular pager
// bx pagers
%bx-pager {
    margin: $block-margin 0 0;
    text-align: center;

    .bx-pager-item {
        display: inline-block;
        margin: 0 5px;
    }

    .bx-pager-link {
        display: block;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: $color-quaternary;

        &:hover,
        &.active {
            background: $color-secondary;
        }
    }
}

//==========  Title styles  ==========//

// sub-title styles
// used across titles, buttons, etc
%sub-title {
    font-weight: $font-black;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

.sr-only,
%sr-only {
	position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}

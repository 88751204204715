
//==========  Borders  ==========//

// build our default border style 

$border-size: 1px;
$border-style: solid;
$border-color: $color-lightgrey;
$border-thick: 3px;

// combine border variables into a default
// this is used throughout our modules as a base
$border: $border-size $border-style $border-color;

$border-blue: $border-thick $border-style $color-quaternary;

$border-white: $border-size $border-style rgba($color-white, 0.5);

// set a default border-radius
// this is used in our forms and search styling
$border-radius: 100px;

// inner box-shadow style for profiles / staff images
// smaller variable is used on small scale images
$box-shadow-inset: inset 0px 5px 30px 10px rgba($color-black, 0.15);

$box-shadow-inset-small: inset 0 2px 12px 4px rgba($color-black, 0.3);

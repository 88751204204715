
// Content styles
.block-text,
.biography {
	line-height: 1.7;

	// Default paragraphs
	p {
		margin: 0 0 $paragraph-margin;

		&.intro {
			color: $color-sub;
			font-family: $font-family-sub;
			font-size: $h5;

			@media #{$mobileXL} {
				font-size: $h4;
			}

			// eww content import
			strong {
				font-weight: $font-light;
			}
		}
	}

	h1 {
		@extend %sr-only;
	}

	// reset any <ul> that will appear in a template here
	// maintain vertical rhythm with paragraph margin
	ul[class] {
		@extend %reset-ul;
		margin: 0 0 $paragraph-margin;
	}

	// New default <ul> lists
	ul {
		@extend %default-ul;

		ul {
			@extend %default-ul;
		}
	}

	// New default <ol> lists
	ol {
		@extend %default-ol;

		ol {
			@extend %default-ol;
		}
	}

	blockquote {
		font-size: $h5;
		border-left: 4px solid $color-quaternary;
		margin: 0 0 $paragraph-margin 0;
		padding: $block-padding * 2;
		font-style: italic;
		background: $color-lightgrey;

		p {
			margin-bottom: 0;
		}
	}
}
// CASE : 143908
// 0B - 12/03/19
// for p tags that are not within block-text (js stuff)
// .content {
// 	p {
// 		line-height: 1.7;
// 	}
// }

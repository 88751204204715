
// Default .button class
.button {
    @extend %button;


    // long text on buttons is wrapping
    // swap to min-height and padding
    &.small {
        min-height: $button-height * 0.75;
        // line-height: $button-height * 0.75;
        padding-top: ($block-padding * 1/3);
        padding-bottom: ($block-padding * 1/3);
        font-size: 0.7rem;
    }

    &.large {
        min-height: $button-height-large;
        // line-height: $button-height-large;
        padding-top: $block-padding;
        padding-bottom: $block-padding;
        font-size: 1.1rem;
    }


    &.fill {

        &-brand {
            background: $color-primary;
            color: $color-white;
        }

        &-alt {
            background: $color-secondary;
            color: $color-white;
        }

        &-tone {
            background: $color-white;
            color: $color-secondary;
        }
    }

    &.to-fill {

        &-brand {

            &:hover {
                background: $color-primary;
                color: $color-white;
            }
        }

        &-alt {

            &:hover {
                background: $color-secondary;
                color: $color-white;
            }
        }

        &-tone {

            &:hover {
                background: $color-white;
                color: $color-primary;
            }
        }
    }


    &.outline {

        &-brand {
            border-color: $color-primary;
            color: $color-primary;
        }

        &-alt {
            border-color: $color-secondary;
            color: $color-secondary;
        }

        &-tone {
            border-color: $color-white;
            color: $color-white;
        }
    }

    &.to-outline {

        &-brand {

            &:hover {
                border-color: $color-primary;
                color: $color-primary;
            }
        }

        &-alt {

            &:hover {
                border-color: $color-secondary;
                color: $color-secondary;
            }
        }

        &-tone {

            &:hover {
                border-color: $color-white;
                color: $color-white;
            }
        }
    }


    a {
        color: inherit;
        transition: inherit;
    }
}

.button-arrow {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: .5rem;
    color: $color-black;
    font-weight: $font-bold;

    &:hover {
        color: $color-primary;
    }

    &:after {
        content: '';
        display: block;
        width: 30px;
        height: 31px;
        background: url('/images/icons/orange-arrow.svg') no-repeat center center;
        background-size: contain;
    }
}

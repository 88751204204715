
//==========  Buttons  ==========//

// set standard button heights

$button-height: 50px;

$button-height-small: $button-height * 0.75;

$button-height-large: $button-height * 1.25;
